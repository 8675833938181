import * as APIs from "../constants/BackendConstant";
import { axiosInstance } from "../utilities/configureAxios";
import * as ApiResponse from "../constants/ApiResponse";
import { getMockValue } from "../utilities/helper";

export const getOtp = (body) => {
  let url = APIs.GET_OTP;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.generate);
  }
  return axiosInstance.post(url, body);
};

export const submitOtp = (body) => {
  let url = APIs.SUBMIT_OTP;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.verify);
  }
  return axiosInstance.post(url, body);
};

export const skipLoginApi = () => {
  let url = APIs.SKIP_LOGIN;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.skip_login);
  }
  return axiosInstance.post(url, {});
};

export const resendOtpApi = (body) => {
  let url = APIs.RESEND_OTP;
  return Promise.resolve(ApiResponse.resend_otp);
  if (getMockValue()) {
  }
  return axiosInstance.post(url, body);
};

export const getUserProfiles = () => {
  let url = APIs.GET_USER_PROFILES;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.user_profiles);
  }

  return axiosInstance.get(url, {
    headers: { uid: "" },
  });
};

export const updateProfile = (id, payload) => {
  let url = APIs.UPDATE_PROFILE.replace(":profile_id", id);
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.update_profile);
  }
  return axiosInstance.put(url, payload, {
    headers: { uid: "" },
  });
};

export const createProfile = (payload) => {
  let url = APIs.CREATE_PROFILE;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.create_profile);
  }
  return axiosInstance.post(url, payload, {
    headers: { uid: "" },
  });
};

export const getUserDetails = (uid) => {
  let url = APIs.USER_DETAILS;
  const headers = {
    uid: uid ?? "",
  };
  return axiosInstance.get(url, {
    headers: headers,
  });
};

export const validateReferral = (payload) => {
  let url = APIs.VALIDATE_REFERRAL;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.validate_referral);
  }
  return axiosInstance.post(url, payload);
};

export const getShareableLink = (payload) => {
  let url = APIs.GET_SHAREABLE_ID;
  return axiosInstance.post(url, payload);
};
