import { useState, useEffect } from "react";
import BaseLayout from "../layout/BaseLayout";
import { useTranslation } from "react-i18next";
import { Divider, Switch } from "antd";
import { setHeaders } from "../utilities/helper";

export const optionsList = [
  {
    name: "Speaker",
    key: "jivi-flow-speaker",
  },
  {
    name: "Voice",
    key: "jivi-flow-voice",
  },
  {
    name: "Transcription",
    key: "jivi-flow-transcription",
  },
  {
    name: "Camera",
    key: "jivi-flow-camera",
  },
  {
    name: "Auto Stop",
    key: "jivi-flow-auto-stop",
  },
  {
    name: "Dev-Mode",
    key: "jivi-dev-mode",
    action: (value) => {
      setHeaders("X-MODE", value ? "DEMO" : "");
    },
  },
];

const OptionsPage = () => {
  const { t } = useTranslation();
  const [optionsState, setOptionsState] = useState({});

  // Initialize state with localStorage values
  useEffect(() => {
    const initialState = optionsList.reduce((acc, option) => {
      acc[option.key] = localStorage.getItem(option.key) === "true";
      return acc;
    }, {});
    setOptionsState(initialState);
  }, []);

  const handleSwitchChange = (key, checked) => {
    // Update state
    setOptionsState((prevState) => ({
      ...prevState,
      [key]: checked,
    }));

    const option = optionsList.find((option) => option.key === key);

    if (option?.action) {
      option.action(checked);
    }

    // Save to localStorage
    localStorage.setItem(key, checked);
  };

  return (
    <BaseLayout title={t("options")}>
      <div className="flex flex-col w-full py-4 px-2 overflow-y-scroll">
        {optionsList.map((option) => (
          <div
            key={option.key}
            className="flex flex-col gap-4 w-full py-4 px-2"
          >
            <p className="font-medium text-lg">{t(option.name)}</p>
            <div className={`border-2 shadow-lg rounded-xl p-4`}>
              <div
                onClick={() =>
                  handleSwitchChange(option.key, !optionsState[option.key])
                }
              >
                <p
                  className={`${optionsState[option.key] ? "text-primary" : ""}`}
                >
                  ON
                </p>
              </div>
              <Divider className="border-4" type="horizontal" />
              <div onClick={() => handleSwitchChange(option.key, false)}>
                <p
                  className={`${optionsState[option.key] ? "" : "text-primary"}`}
                >
                  OFF
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </BaseLayout>
  );
};

export default OptionsPage;
