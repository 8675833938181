import axios from "axios";
import * as Sentry from "@sentry/react";

let apiUrl = import.meta.env.VITE_DS_API_BASE_URL;

const configureAxios = () =>
  axios.create({
    baseURL: apiUrl,
    timeout: 180000,
  });

export const axiosInstanceDs = configureAxios();

axiosInstanceDs.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

axiosInstanceDs.defaults.headers.common["Authorization"] =
  "Basic ZHNfdXNlcjpkc0AxMjM=";

axiosInstanceDs.interceptors.request.use((config) => {
  return config;
});

axiosInstanceDs.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    Sentry.captureException(error);
    if (!(error && error.response)) {
      error = {
        response: {
          data: {
            errors: {
              message: "Something went wrong.",
            },
          },
        },
      };
    }
    return Promise.reject(error);
  },
);
