import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import limitatonImage from "../assets/images/limitations.svg";
import PrimaryButton from "../subComponents/PrimaryButton";
import SecondaryButton from "../subComponents/SecondaryButton";
import { isKiosk } from "../utilities/helper";

const ErrorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={`flex flex-col justify-center h-screen w-full ${isKiosk() ? "w-full" : "md:max-w-[430px]"}`}>
      <img src={limitatonImage} draggable={false} className="max-h-[50%]" />
      <div className="px-8 flex flex-col gap-8">
        <p className="text-heading-large">{t("something_went_wrong")}</p>
        <p className="text-body-large opacity-50">
          This page isn't available. Sorry about that.
        </p>
        <div className="flex gap-4">
          <PrimaryButton
            text={t("retry")}
            onClick={() => {
              window.location.reload(true);
            }}
            showIcon={false}
          />
          <SecondaryButton
            width="w-full"
            text={t("home")}
            onClick={() => {
              navigate("/", {
                replace: true,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default ErrorPage;
