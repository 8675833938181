import { LeftOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ShareModal from "../subComponents/ShareModal";
import shareIcon from "../assets/images/share/share.svg";
import header from "../assets/images/share/header.svg";
import footer from "../assets/images/share/footer.svg";
import { isAppInstalled, openExternalLink } from "../utilities/helper";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { triggerAppBackButton } from "../utilities/helper";
import globe from "../assets/images/icons/globe.svg";

const BaseLayout = ({
  children,
  extra,
  title,
  leftIcon,
  headerBackgroundColor = "bg-transparent",
  headerTextColor = "text-slate-800",
  showDivider = true,
  onClick = null,
  showBack = true,
  backLink = null,
  backButtonBg = "bg-slate-100",
  headerRef = null,
  share = false,
  shareOptions = {},
  isSticky = false,
  height = "h-dvh",
  showLanguage = false,
  languageState = {},
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const shareableId = searchParams.get("shareableId");
  const viewOnly = shareableId ? true : false;
  const [showShareModal, setShowShareModal] = useState(false);
  const appInstalled = isAppInstalled();

  return (
    <div
      className={`w-screen ${height} flex flex-col overflow-hidden relative`}
    >
      {viewOnly && !appInstalled && (
        <div className="flex justify-start pt-5 pl-5 py-2">
          <img src={header} alt="header" className="h-7" />
        </div>
      )}
      <div
        ref={headerRef}
        className={`flex justify-between items-center w-full px-6 py-3 ${headerBackgroundColor} ${isSticky ? "absolute top-0 left-0 z-10" : ""}`}
      >
        <div className="grow flex items-center gap-4 overflow-hidden">
          {showBack && (
            <div
              className={`w-9 h-9 p-2.5 ${backButtonBg} rounded-xl justify-center items-center gap-2.5 inline-flex cursor-pointer`}
              onClick={
                onClick
                  ? onClick
                  : () => {
                      if (
                        window?.history?.length === 1 ||
                        window?.history?.state?.idx === 0
                      ) {
                        navigate("/", { replace: true });
                        triggerAppBackButton();
                      } else {
                        navigate(backLink ?? -1);
                      }
                    }
              }
            >
              <LeftOutlined className="w-6 h-6" />
            </div>
          )}
          <div
            className={`${headerTextColor} text-body-large grow overflow-hidden whitespace-nowrap text-ellipsis`}
          >
            {title ?? t("doctor_jivi")}
          </div>
        </div>
        <div className="flex flex-none gap-3">
          {leftIcon}
          {extra}
          {!viewOnly && shareOptions.id && share && (
            <button onClick={() => setShowShareModal(true)}>
              <img src={shareIcon} alt="share" />
            </button>
          )}
          {showLanguage && (
            <div
              onClick={() =>
                navigate(
                  `/select-language?referrer=${window.location.pathname.replace(import.meta.env.VITE_API_URL_PREFIX, "/")}${window.location.search}`,
                  {
                    state: languageState,
                  }
                )
              }
              className="w-fit bg-white rounded-xl shadow-[0px_4px_20px_0px_rgba(0,0,0,0.10)] flex justify-between items-center gap-3 px-3 py-2"
            >
              <div className="text-orange-500 text-label-medium">
                {localStorage.getItem("selectedLanguageName") ?? "English"}
              </div>
              <img src={globe} className="w-4 h-4" />
            </div>
          )}
        </div>
      </div>
      {showDivider && <Divider className="m-0" />}
      {children}
      {viewOnly && !appInstalled && (
        <img
          src={footer}
          alt="footer"
          className="w-full pt-2"
          onClick={() =>
            openExternalLink(import.meta.env.VITE_ONE_LINK, "_blank")
          }
        />
      )}
      {share && (
        <ShareModal
          id={shareOptions.id}
          type={shareOptions.type}
          open={showShareModal}
          onClose={() => setShowShareModal(false)}
          textContent={shareOptions.textContent}
        />
      )}
    </div>
  );
};

export default BaseLayout;
