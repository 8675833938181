import * as SERVICEs from "../services/Chat";

export const getSessionToken = (body) => {
  return SERVICEs.getSessionToken(body)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const editSessionData = (sessionId, payload) => {
  return SERVICEs.editSessionData(sessionId, payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTopSymptoms = () => {
  return SERVICEs.getTopSymptoms()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTrendingIssues = () => {
  return SERVICEs.getTrendingIssues()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSearchSymptoms = (key) => {
  return SERVICEs.getSearchSymptoms(key)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const guidedChat = (data, options = {}) => {
  return SERVICEs.guidedChat(data, options)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const freeChat = (data) => {
  return SERVICEs.freeChat(data)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSummary = (sessionId, queryParams) => {
  return SERVICEs.getSummary(sessionId, queryParams)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getDifferentialDiagnosis = (sessionId, queryParams) => {
  return SERVICEs.getDifferentialDiagnosis(sessionId, queryParams)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTranscribe = (data) => {
  return SERVICEs.getTranscribe(data)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const chatHistory = (page, speciality, type, limit = null) => {
  return SERVICEs.chatHistory(page, speciality, type, limit)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const sessionDetails = (sessionId, queryParams = {}) => {
  return SERVICEs.sessionDetails(sessionId, queryParams)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateVitals = (data, sessionId) => {
  return SERVICEs.updateVitals(data, sessionId)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getConversation = (sessionId) => {
  return SERVICEs.getConversation(sessionId)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getAudioFromText = (text, options = {}) => {
  return SERVICEs.getAudioFromText(text, options)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const editChat = (sessionId, messageId) => {
  return SERVICEs.editChat(sessionId, messageId)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const uploadFile = (payload) => {
  return SERVICEs.uploadFile(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getDocsFromSessionId = (sessionId) => {
  return SERVICEs.getDocsFromSessionId(sessionId)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const uploadInternalFile = (payload) => {
  return SERVICEs.uploadInternalFile(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getTreatments = (payload) => {
  return SERVICEs.getTreatments(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const analyseReports = (payload) => {
  return SERVICEs.analyseReports(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getChatMessages = (sessionType, sessionId) => {
  return SERVICEs.getChatMessages(sessionType, sessionId)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getDocs = (payload) => {
  return SERVICEs.getDocs(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSecondOpinionChat = (payload) => {
  return SERVICEs.getSecondOpinionChat(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getScreeningChat = (payload) => {
  return SERVICEs.getScreeningChat(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSecondOpinionDDx = (payload, queryParams) => {
  return SERVICEs.getSecondOpinionDDx(payload, queryParams)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getDiseasesList = (query, limit) => {
  return SERVICEs.getDiseasesList(query, limit)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getNextStepsAndTreatment = (payload, queryParams) => {
  return SERVICEs.getTreatmentsAndNextSteps(payload, queryParams)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSecondOpinionHistory = (page, type) => {
  return SERVICEs.getSecondOpinionHistory(page, type)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const editSession = (payload) => {
  return SERVICEs.editSession(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const followupChat = (payload) => {
  return SERVICEs.followupChat(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};
