import { useTranslation } from "react-i18next";
import LoadingJson from "../../assets/lotties/loading.json";
import { Player } from "@lottiefiles/react-lottie-player";

const KioskLoader = ({ description = null }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col w-full min-h-full relative">
      <div className="grow h-full w-full flex justify-center items-center">
        <div className="text-center flex flex-col gap-8 w-[70%]">
          <Player className="w-1/2" src={LoadingJson} autoplay loop />
          <p className="text-md font-medium">{t("loading")}...</p>
          <p className="text-sm text-black text-opacity-50">
            {description ?? t("loading_description")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default KioskLoader;
