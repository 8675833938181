import { useState, useEffect, useRef } from "react";
import jiviLogo from "../../assets/images/logo.svg";
import {
  useSearchParams,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { getConversation, guidedChat } from "../../dataManager/Chat";
import { getTextFromSpeech } from "../../utilities/apiHelper";
import { getAudioFromText } from "../../dataManager/Chat";
import audioIconOrange from "../../assets/images/audio-orange.svg";
import audioIconBlue from "../../assets/images/audio-blue.svg";
import sendBlueIcon from "../../assets/images/send-blue.svg";
import { Checkbox, Modal, Radio, Switch } from "antd";
import PrimaryButton from "../../subComponents/PrimaryButton";
import SecondaryButton from "../../subComponents/SecondaryButton";
import { useTranslation } from "react-i18next";
import BaseLayout from "../../layout/BaseLayout";
import { Player } from "@lottiefiles/react-lottie-player";
import LoadingJson from "../../assets/lotties/loading.json";
import { allExceptLast } from "../../utilities/helper";
import { optionsList } from "../Options";
import userBlue from "../../assets/images/user-blue.svg";
import BarsLoader from "../../components/kiosk/bars-loader";
import resumeIcon from "../../assets/images/resume.svg";
import pauseIcon from "../../assets/images/pause.svg";

const workletCode = `
class AudioProcessor extends AudioWorkletProcessor {
    process(inputs, outputs, parameters) {
        const input = inputs[0];
        const channel = input[0];
        
        if (channel) {
            // Convert Float32Array to Int16Array
            const pcmData = new Int16Array(channel.length);
            for (let i = 0; i < channel.length; i++) {
                const sample = Math.max(-1, Math.min(1, channel[i]));
                pcmData[i] = sample < 0 ? sample * 0x8000 : sample * 0x7fff;
            }
            
            // Send the PCM data to the main thread
            this.port.postMessage(pcmData.buffer, [pcmData.buffer]);
        }
        
        return true; // Keep the processor running
    }
}

registerProcessor('audio-processor', AudioProcessor);
`;

const customInputHeaderCodes = [
  "summary",
  "vitals",
  "attachment",
  "confirmation",
];

const KioskDrJiviSession = () => {
  const { t } = useTranslation();
  const [voiceMode, setVoiceMode] = useState(true);
  const { speciality } = useParams();
  const location = useLocation();
  const [currentQuestion, setCurrentQuestion] = useState({
    text: "",
    b64: "",
    meta: {},
    allMessages: [],
  });
  const [quickLinks, setQuickLinks] = useState([]);
  const [conversation, setConversation] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const optionsRef = useRef({});
  const [lastHumanMessage, setLastHumanMessage] = useState("");
  const [paused, setPaused] = useState(false);

  const [selectedReport, setSelectedReport] = useState([]);
  const [showForusModal, setShowForusModal] = useState(false);
  const videoRef = useRef(null);
  const videoPlayerRef = useRef(null);
  const audioRef = useRef(null);
  const audioPlayerRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");

  const audioContextRef = useRef(null);
  const workletNodeRef = useRef(null);
  const inputRef = useRef(null);
  const socketRef = useRef(null);
  const streamRef = useRef(null);
  const analyzerRef = useRef(null);
  const animationRef = useRef(null);

  const [overDecibel, setOverDecibel] = useState(false);
  const decibelLimit = -40;

  const navigate = useNavigate();

  const getVideoStream = async () => {
    if (!optionsRef.current["jivi-flow-camera"]) {
      return;
    }
    try {
      // Get video stream
      videoRef.current = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });
      if (videoPlayerRef.current) {
        videoPlayerRef.current.srcObject = videoRef.current;
      }
    } catch (err) {
      console.error("Error accessing media devices:", err);
    }
  };

  const getAudioStream = async () => {
    try {
      // Get audio stream
      const audioStream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
      });
      if (audioStream) {
        audioRef.current = audioStream;
        return audioStream;
      }
    } catch (err) {
      console.error("Error accessing media devices:", err);
    }
  };

  const releaseAudioStream = () => {
    if (audioRef.current) {
      audioRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  const releaseVideoStream = () => {
    if (videoRef.current) {
      videoRef.current.getTracks().forEach((track) => track.stop());
    }

    if (videoPlayerRef.current) {
      videoPlayerRef.current.srcObject = null;
    }
  };

  const cleanup = () => {
    if (workletNodeRef.current) {
      workletNodeRef.current.disconnect();
      workletNodeRef.current = null;
    }

    if (inputRef.current) {
      inputRef.current.disconnect();
      inputRef.current = null;
    }

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.close();
      socketRef.current = null;
    }

    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }

    if (analyzerRef.current) {
      analyzerRef.current.disconnect();
      analyzerRef.current = null;
    }

    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
      animationRef.current = null;
    }
  };

  useEffect(() => {
    const initialState = optionsList.reduce((acc, option) => {
      if (localStorage.getItem(option.key) === null) {
        localStorage.setItem(option.key, true);
      }
      acc[option.key] = localStorage.getItem(option.key) === "true";
      return acc;
    }, {});

    optionsRef.current = initialState;

    if (voiceMode) {
      getVideoStream();
    }
    getConversationsApi();

    return () => {
      releaseAudioStream();
      releaseVideoStream();
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (voiceMode) {
      getVideoStream();
      // currentQuestion.b64 !== "" && playAudio(currentQuestion.b64);
    } else {
      releaseAudioStream();
      releaseVideoStream();
      stopAudio();
      cleanup();
      stopRecording(true);
    }
  }, [voiceMode]);

  const initAudioWorklet = async (audioContext) => {
    const blob = new Blob([workletCode], { type: "application/javascript" });
    const url = URL.createObjectURL(blob);
    await audioContext.audioWorklet.addModule(url);
    URL.revokeObjectURL(url);
  };

  const onConfirm = () => {
    sendMessageGuided(
      selectedSymptoms.length > 0 ? selectedSymptoms.join(",") : "none"
    );
  };

  const startRecording = async () => {
    if (!optionsRef.current["jivi-flow-voice"]) {
      return;
    }

    socketRef.current = new WebSocket(import.meta.env.VITE_WS_SPEECH_URL);
    socketRef.current.binaryType = "arraybuffer";

    socketRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const event_type = data.result.speech_event_type;
      if (!optionsRef.current["jivi-flow-auto-stop"]) {
        return;
      }
      if (event_type === 3) {
        stopRecording();
      }
    };

    await getAudioStream();
    mediaRecorderRef.current = new MediaRecorder(audioRef.current);

    mediaRecorderRef.current.ondataavailable = (e) => {
      const audioBlob = e.data;
      processAudio(audioBlob);
    };

    audioContextRef.current = new (window.AudioContext ||
      window.webkitAudioContext)();
    await initAudioWorklet(audioContextRef.current);
    inputRef.current = audioContextRef.current.createMediaStreamSource(
      audioRef.current
    );

    analyzerRef.current = audioContextRef.current.createAnalyser();
    analyzerRef.current.fftSize = 256; // Set FFT size for frequency analysis
    const dataArray = new Uint8Array(analyzerRef.current.frequencyBinCount);

    workletNodeRef.current = new AudioWorkletNode(
      audioContextRef.current,
      "audio-processor"
    );
    inputRef.current.connect(workletNodeRef.current);
    inputRef.current.connect(analyzerRef.current);
    workletNodeRef.current.connect(audioContextRef.current.destination);
    workletNodeRef.current.port.onmessage = (event) => {
      if (socketRef.current?.readyState === WebSocket.OPEN) {
        socketRef.current.send(event.data);
      }
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);
  };

  useEffect(() => {
    if (isRecording) {
      let lastTimestamp = 0;

      const monitorDecibels = (timestamp) => {
        if (!analyzerRef.current) return;

        if (timestamp - lastTimestamp >= 100) {
          lastTimestamp = timestamp;

          // Create an array to hold time-domain data
          const timeDomainArray = new Uint8Array(analyzerRef.current.fftSize);
          analyzerRef.current.getByteTimeDomainData(timeDomainArray);

          // Calculate RMS (Root Mean Square) amplitude
          const rms = Math.sqrt(
            timeDomainArray.reduce((sum, value) => {
              const normalized = (value - 128) / 128; // Normalize between -1 and 1
              return sum + normalized * normalized;
            }, 0) / timeDomainArray.length
          );

          // Convert RMS to decibels
          const decibels = rms > 0 ? (20 * Math.log10(rms)).toFixed(2) : -100; // Set minimum dB to -100
          setOverDecibel(decibels > decibelLimit);
        }

        // Continue monitoring if still recording
        if (isRecording) {
          animationRef.current = requestAnimationFrame(monitorDecibels);
        }
      };

      animationRef.current = requestAnimationFrame(monitorDecibels);

      monitorDecibels();
    }
  }, [isRecording]);

  const stopRecording = (forceStop = false) => {
    if (!mediaRecorderRef.current) return;
    if (forceStop) {
      mediaRecorderRef.current.ondataavailable = null;
    }
    mediaRecorderRef.current.stop();
    cleanup();
    setIsRecording(false);
    releaseAudioStream();
  };

  const processAudio = async (audioBlob) => {
    setLoading(true);
    const text = await getTextFromSpeech(audioBlob);
    setLoading(false);
    sendMessageGuided(text);
  };

  const playAudio = (src, startRecordingAfterEnded = false) => {
    if (!optionsRef.current["jivi-flow-speaker"]) {
      startRecordingAfterEnded && startRecording();
      return;
    }

    const audioPlayer = audioPlayerRef.current;
    audioPlayer.src = src;
    audioPlayer.play();
    setIsPlaying(true);

    if (voiceMode) {
      audioPlayer.onended = () => {
        setIsPlaying(false);
        startRecordingAfterEnded && startRecording();
        audioPlayer.onended = null;
      };
    }
  };

  const stopAudio = () => {
    const audioPlayer = audioPlayerRef.current;
    audioPlayer.onended = null;
    audioPlayer.pause();
    setIsPlaying(false);
  };

  const getConversationsApi = async () => {
    setLoading(true);
    const response = await getConversation(sessionId);
    setLoading(false);
    const messages = response.data.data.messages;
    const lastMessage = messages[messages.length - 1];
    if (lastMessage && lastMessage.type === "ai") {
      const text =
        lastMessage.data.additional_kwargs.response[
          lastMessage.data.additional_kwargs.response.length - 1
        ].header;
      const meta =
        lastMessage.data.additional_kwargs.response[
          lastMessage.data.additional_kwargs.response.length - 1
        ];
      askQuestion(text, meta, lastMessage.data.additional_kwargs.response);
      setConversation((conversation) => [
        {
          type: "ai",
          text: lastMessage.data.content_en,
        },
        ...conversation,
      ]);
    } else {
      navigate(`/`);
    }
  };

  const askQuestion = async (question, meta, allMessages = []) => {
    if (meta.header_code === "summary") {
      navigate(`/kiosk/dr-jivi-ddx?sessionId=${sessionId}`);
    }
    setLoading(true);
    const textForAudio =
      allMessages.length > 0
        ? allMessages.map((message) => message.header).join("\n")
        : question;

    const base64 = await getAudioFromText(textForAudio);
    setLoading(false);
    setCurrentQuestion({
      text: question,
      b64: base64.data.data.audio,
      meta: meta,
      allMessages: allMessages,
    });
    setQuickLinks(meta.choices || []);
    playAudio(
      base64.data.data.audio,
      !customInputHeaderCodes.includes(meta.header_code)
    );
  };

  const sendMessageGuided = async (message) => {
    setShowForusModal(false);
    setSelectedReport([]);
    stopRecording();
    const reply = [
      {
        header_code: currentQuestion?.meta.header_code,
        code: typeof message === typeof "" ? [message] : message,
        symptom_code: currentQuestion?.meta.symptoms_code,
      },
    ];

    let payload = {
      session_id: sessionId,
      return_query: true,
      meta: {},
    };

    payload.symptoms = reply;

    if (speciality) payload.specialist = speciality;

    setConversation((conversation) => [
      {
        type: "user",
        text: message,
      },
      ...conversation,
    ]);

    setLastHumanMessage(message);

    setLoading(true);
    const response = await guidedChat(payload);
    setLoading(false);

    const text =
      response.data.data.response[response.data.data.response.length - 1]
        .header;
    const meta =
      response.data.data.response[response.data.data.response.length - 1];

    askQuestion(text, meta, response.data.data.response);

    setConversation((conversation) => [
      {
        type: "ai",
        text: response.data.data.response[
          response.data.data.response.length - 1
        ].header,
      },
      ...conversation,
    ]);
  };

  return (
    <BaseLayout
      title={t("talk_to_jivi")}
      backLink="/"
      extra={
        <div className="flex gap-3 items-center">
          <div className="flex gap-2 items-center">
            <p className="text-primary text-sm font-medium">Voice</p>
            <Switch
              className=""
              defaultChecked={voiceMode}
              onChange={(checked) => {
                setVoiceMode(checked);
              }}
            />
          </div>
          <div>
            {paused ? (
              <img
                src={resumeIcon}
                alt="Resume"
                className="h-4 cursor-pointer"
                onClick={() => {
                  setPaused(false);
                  playAudio(currentQuestion.b64);
                }}
              />
            ) : (
              <img
                src={pauseIcon}
                alt="Pause"
                className="h-4 cursor-pointer"
                onClick={() => {
                  setPaused(true);
                  stopAudio();
                  stopRecording(true);
                }}
              />
            )}
          </div>
        </div>
      }
      showLanguage={true}
      languageState={location.state}
    >
      <div className="h-full w-full absolute -z-50 bg-gradient-to-bl from-primaryLight from-10% via-white via-50% to-white to-100%" />
      <audio ref={audioPlayerRef} className="hidden">
        <source src="https://dl.espressif.com/dl/audio/ff-16b-2c-44100hz.mp3" />
      </audio>
      <div className={"grow flex flex-col " + (voiceMode ? "" : "hidden")}>
        <div className="basis-1/2 max-h-[40vh]">
          <div className="flex flex-row gap-12 h-full px-12 py-12">
            <div className="basis-1/2 flex flex-col gap-4 h-full">
              <div
                className={
                  "flex justify-center items-center w-full gap-2 " +
                  (isPlaying ? "visible" : "invisible")
                }
              >
                <img src={audioIconOrange} alt="Audio Icon" className="h-4" />
                <p className="font-medium text-sm">{t("speaking")}</p>
              </div>
              <div
                className={
                  "rounded-xl border flex justify-center items-center grow shadow-[5px_5px_70px_5px_rgba(0,0,0,0.1)] " +
                  (isPlaying ? "shadow-primary" : "shadow-none")
                }
              >
                <img className="w-1/2" src={jiviLogo} alt="Jivi Logo" />
              </div>
            </div>
            <div className="basis-1/2 flex flex-col gap-4 h-full">
              <div
                className={
                  "flex justify-center items-center w-full gap-2 " +
                  (isRecording ? "visible" : "invisible")
                }
              >
                {/* <img src={audioIconBlue} alt="Audio Icon" className="h-4" /> */}
                <BarsLoader
                  color="#0F67FE"
                  size="60px"
                  speed="1s"
                  enabled={overDecibel}
                />
                <p className="font-medium text-sm">{t("speaking")}</p>
              </div>
              <div
                className={
                  "rounded-xl border-2 grow relative shadow-[5px_5px_70px_5px_rgba(0,0,0,0.1)] " +
                  (isRecording
                    ? "shadow-primaryBlue border-primaryBlue"
                    : "shadow-none")
                }
              >
                {isRecording && (
                  <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 z-10">
                    <img
                      src={sendBlueIcon}
                      alt="Send Icon"
                      className="h-12 cursor-pointer"
                      onClick={() => stopRecording()}
                    />
                  </div>
                )}
                <video
                  ref={videoPlayerRef}
                  autoPlay
                  muted
                  className="h-full object-cover rounded-xl"
                  style={{ transform: "scaleX(-1)" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="basis-full max-h-[50vh] bg-gradient-to-b from-primaryLight to-white p-6 border-t border-primary border-opacity-50 overflow-y-scroll bg-red-500">
          {(loading || currentQuestion.text === "") && (
            <div className="flex flex-col w-full justify-center items-center">
              <p className="font-medium text-lg text-center">
                {currentQuestion.text}
              </p>
              <Player className="w-full" src={LoadingJson} autoplay loop />
              <p className="font-medium text-sm opacity-60 text-black">
                {t("processing") + "..."}
              </p>
            </div>
          )}
          {!loading && (
            <div className="flex flex-col justify-center items-center gap-6">
              {lastHumanMessage &&
                optionsRef.current["jivi-flow-transcription"] && (
                  <div className="w-full flex justify-end">
                    <div className="max-w-[75%] px-4 py-2 rounded-xl border border-primaryBlue flex flex-row gap-4 items-center">
                      <p className="text-md font-medium">{lastHumanMessage}</p>
                      <img src={userBlue} alt="User" className="h-8" />
                    </div>
                  </div>
                )}
              {allExceptLast(currentQuestion.allMessages).map(
                (message, index) => {
                  return (
                    <>
                      {message.header && (
                        <div
                          key={index}
                          className="flex flex-row gap-6 items-center"
                        >
                          <p className="font-medium text-xl">
                            {message.header}
                          </p>
                        </div>
                      )}
                      {message?.raw_report?.forus_report &&
                        message?.raw_report?.forus_report.length > 0 && (
                          <div
                            key={index}
                            className="flex flex-row gap-6 items-center"
                          >
                            <div className="flex flex-col gap-4">
                              <div className="grid grid-cols-3 gap-4">
                                {message?.raw_report?.forus_report.map(
                                  (report, index) =>
                                    report.image?.s3_presiged && (
                                      <img
                                        src={report.image?.s3_presiged}
                                        className="w-full rounded-xl"
                                        key={index}
                                      />
                                    )
                                )}
                              </div>
                              <div
                                className="w-fit px-8 py-2 bg-white rounded-xl justify-center items-center gap-2 cursor-pointer"
                                onClick={() => {
                                  setSelectedReport(
                                    message?.raw_report?.forus_report
                                  );
                                  setShowForusModal(true);
                                  stopRecording(true);
                                  stopAudio();
                                }}
                              >
                                <div className="text-primary text-body-medium">
                                  {t("view_details")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </>
                  );
                }
              )}
              <p className="font-medium text-lg text-center">
                {currentQuestion.text}
              </p>
              <div className="flex flex-row gap-4 flex-wrap justify-center">
                {quickLinks.map((link, index) => (
                  <div
                    key={index}
                    className="border border-primary py-1 px-2 rounded-xl cursor-pointer"
                    onClick={() => {
                      stopRecording(true);
                      stopAudio();
                      sendMessageGuided(link);
                    }}
                  >
                    <p className="text-md grow text-nowrap">{link}</p>
                  </div>
                ))}
              </div>
              {currentQuestion?.meta?.header_code === "confirmation" && (
                <>
                  <div className="flex flex-col gap-4 w-full max-h-[25vh] overflow-y-scroll">
                    {currentQuestion.meta.questions.map((question, index) => (
                      <div
                        key={index}
                        className={`py-2 px-4 rounded-xl cursor-pointer shadow-md flex items-center bg-white w-full h-10 border-4 ${selectedSymptoms.includes(question.code) ? "border-primary" : ""}`}
                        onClick={() => {
                          if (question.code === "none") {
                            setSelectedSymptoms([question.code]);
                            return;
                          }
                          if (selectedSymptoms.includes(question.code)) {
                            setSelectedSymptoms(
                              selectedSymptoms.filter(
                                (symptom) => symptom !== question.code
                              )
                            );
                          } else {
                            setSelectedSymptoms([
                              ...selectedSymptoms.filter(
                                (symptom) => symptom !== "none"
                              ),
                              question.code,
                            ]);
                          }
                        }}
                      >
                        <p className="text-xl">
                          {question.code === "none" ? "None" : question.code}
                        </p>
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={() => {
                      onConfirm();
                    }}
                    className="bg-primary text-white py-2 px-4 rounded-xl w-full"
                  >
                    <p className="text-sm">{t("confirm")}</p>
                  </button>
                </>
              )}
              {currentQuestion?.meta?.header_code === "vitals" && (
                <div className="flex flex-col gap-4 w-full items-center">
                  <SecondaryButton
                    paddingY="py-4"
                    customClass="w-full h-fit"
                    text={t("skip")}
                    onClick={() => {
                      stopAudio();
                      sendMessageGuided("Skip");
                    }}
                  />
                  <PrimaryButton
                    customClass="h-fit w-full"
                    showIcon={false}
                    text={t("share")}
                    onClick={() => {
                      navigate(
                        `${speciality ? `/${speciality}` : ""}/vitals?sessionId=${sessionId}&redirect=${window.location.pathname.replace(import.meta.env.VITE_API_URL_PREFIX, "/")}${window.location.search}`
                      );
                    }}
                  />
                </div>
              )}
              {currentQuestion?.meta?.header_code === "attachment" && (
                <div className="flex flex-col gap-4 w-full items-center">
                  <SecondaryButton
                    paddingY="py-4"
                    customClass="w-full h-fit"
                    text={t("skip")}
                    onClick={() => {
                      stopAudio();
                      sendMessageGuided(t("skip"));
                    }}
                  />
                  <PrimaryButton
                    customClass="h-fit w-full"
                    showIcon={false}
                    text={t("share")}
                    onClick={() => {
                      navigate(
                        `${speciality ? `/${speciality}` : ""}/upload-image-doc?sessionId=${sessionId}&redirect=${window.location.pathname.replace(import.meta.env.VITE_API_URL_PREFIX, "/")}${window.location.search}&type=${speciality}`
                      );
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {!voiceMode && (
        <div className="grow flex flex-col mt-8 mx-4 overflow-scroll">
          {loading && (
            <div className="flex flex-col w-full justify-center items-center">
              <div className="flex flex-row gap-6 items-center">
                <img src={jiviLogo} alt="Jivi Logo" className="h-10" />
                <p className="font-medium text-xl">{currentQuestion.text}</p>
              </div>
              <Player className="w-full" src={LoadingJson} autoplay loop />
              <p className="font-medium text-sm opacity-60 text-black">
                {t("processing") + "..."}
              </p>
            </div>
          )}
          {!loading && (
            <div className="flex flex-col gap-4">
              {lastHumanMessage &&
                optionsRef.current["jivi-flow-transcription"] && (
                  <div className="w-full flex justify-end">
                    <div className="max-w-[75%] px-4 py-2 rounded-xl border border-primaryBlue flex flex-row gap-4 items-center">
                      <p className="text-md font-medium">{lastHumanMessage}</p>
                      <img src={userBlue} alt="User" className="h-8" />
                    </div>
                  </div>
                )}
              {allExceptLast(currentQuestion.allMessages).map(
                (message, index) => {
                  return (
                    <>
                      {message.header && (
                        <div
                          key={index}
                          className="flex flex-row gap-6 items-center"
                        >
                          <img
                            src={jiviLogo}
                            alt="Jivi Logo"
                            className="h-10"
                          />
                          <p className="font-medium text-xl">
                            {message.header}
                          </p>
                        </div>
                      )}
                      {message?.raw_report?.forus_report &&
                        message?.raw_report?.forus_report.length > 0 && (
                          <div
                            key={index}
                            className="flex flex-row gap-6 items-center"
                          >
                            <img
                              src={jiviLogo}
                              alt="Jivi Logo"
                              className="h-10"
                            />
                            <div className="flex flex-col gap-4">
                              <div className="grid grid-cols-3 gap-4">
                                {message?.raw_report?.forus_report.map(
                                  (report, index) =>
                                    report.image?.s3_presiged && (
                                      <img
                                        src={report.image?.s3_presiged}
                                        className="w-full rounded-xl"
                                        key={index}
                                      />
                                    )
                                )}
                              </div>
                              <div
                                className="w-fit px-8 py-2 bg-primaryLight rounded-xl justify-center items-center gap-2 cursor-pointer"
                                onClick={() => {
                                  setSelectedReport(
                                    message?.raw_report?.forus_report
                                  );
                                  setShowForusModal(true);
                                  stopRecording(true);
                                  stopAudio();
                                }}
                              >
                                <div className="text-primary text-body-medium">
                                  {t("view_details")}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </>
                  );
                }
              )}
              <div className="flex flex-row gap-6 items-center">
                <img src={jiviLogo} alt="Jivi Logo" className="h-10" />
                <p className="font-medium text-xl">{currentQuestion.text}</p>
              </div>
              <div className="flex flex-col gap-6 my-8">
                {quickLinks.length > 0 &&
                  quickLinks.map((link, index) => (
                    <div
                      key={index}
                      className="py-2 px-4 rounded-xl cursor-pointer shadow-md flex items-center bg-white"
                      onClick={() => {
                        stopAudio();
                        sendMessageGuided(link);
                      }}
                    >
                      <Radio>
                        <p className="text-lg">{link}</p>
                      </Radio>
                    </div>
                  ))}
                {quickLinks.length === 0 &&
                  // currentQuestion.meta.header_code !== "confirmation" && (
                  !customInputHeaderCodes.includes(
                    currentQuestion.meta.header_code
                  ) && (
                    <div className="flex flex-col gap-2">
                      <p className="text-sm text-center">
                        {t("please_describe_your_symptoms_in_detail")}
                      </p>
                      <textarea
                        value={textareaValue}
                        onChange={(e) => setTextareaValue(e.target.value)}
                        className="border border-primary p-2 rounded-xl"
                      />
                      <PrimaryButton
                        text={t("send")}
                        onClick={() => {
                          stopAudio();
                          sendMessageGuided(textareaValue);
                        }}
                      />
                    </div>
                  )}
              </div>
              {currentQuestion?.meta?.header_code === "confirmation" && (
                <div className="flex flex-col gap-4 ">
                  {currentQuestion.meta.questions.map((question, index) => (
                    <div
                      key={index}
                      className={`py-2 px-4 rounded-xl cursor-pointer shadow-md flex items-center bg-white w-full h-10 border-4 ${selectedSymptoms.includes(question.code) ? "border-primary" : ""}`}
                      onClick={() => {
                        if (question.code === "none") {
                          setSelectedSymptoms([question.code]);
                          return;
                        }
                        if (selectedSymptoms.includes(question.code)) {
                          setSelectedSymptoms(
                            selectedSymptoms.filter(
                              (symptom) => symptom !== question.code
                            )
                          );
                        } else {
                          setSelectedSymptoms([
                            ...selectedSymptoms.filter(
                              (symptom) => symptom !== "none"
                            ),
                            question.code,
                          ]);
                        }
                      }}
                    >
                      <p className="text-xl">
                        {question.code === "none" ? "None" : question.code}
                      </p>
                    </div>
                  ))}
                  <button className="bg-primary text-white py-2 px-4 rounded-xl">
                    <p
                      onClick={() => {
                        onConfirm();
                      }}
                      className="text-sm"
                    >
                      {t("confirm")}
                    </p>
                  </button>
                </div>
              )}
              {currentQuestion?.meta?.header_code === "vitals" && (
                <div className="flex flex-col gap-4 w-full items-center">
                  <SecondaryButton
                    paddingY="py-4"
                    customClass="w-full h-fit"
                    text={t("skip")}
                    onClick={() => {
                      stopAudio();
                      sendMessageGuided("Skip");
                    }}
                  />
                  <PrimaryButton
                    customClass="h-fit w-full"
                    showIcon={false}
                    text={t("share")}
                    onClick={() => {
                      navigate(
                        `${speciality ? `/${speciality}` : ""}/vitals?sessionId=${sessionId}&redirect=${window.location.pathname.replace(import.meta.env.VITE_API_URL_PREFIX, "/")}${window.location.search}`
                      );
                    }}
                  />
                </div>
              )}
              {currentQuestion?.meta?.header_code === "attachment" && (
                <div className="flex flex-col gap-4 w-full items-center">
                  <SecondaryButton
                    customClass="w-full h-fit"
                    paddingY="py-4"
                    text={t("skip")}
                    onClick={() => {
                      stopAudio();
                      sendMessageGuided("Skip");
                    }}
                  />
                  <PrimaryButton
                    customClass="h-fit w-full"
                    showIcon={false}
                    text={t("share")}
                    onClick={() => {
                      navigate(
                        `${speciality ? `/${speciality}` : ""}/upload-image-doc?sessionId=${sessionId}&redirect=${window.location.pathname.replace(import.meta.env.VITE_API_URL_PREFIX, "/")}${window.location.search}&type=${speciality}`
                      );
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <Modal
        open={showForusModal}
        onCancel={() => {
          setShowForusModal(false);
          setSelectedReport([]);
          startRecording();
        }}
        title={<div className="text-title-medium">{t("forus_report")}</div>}
        footer={null}
        centered={true}
        width={"80%"}
        closeIcon={false}
      >
        <div className="flex flex-col gap-4">
          {selectedReport.length > 0 &&
            selectedReport.map((report, index) => (
              <div key={index} className="flex flex-col gap-2">
                <div className="flex flex-col gap-2 border bg-white rounded-xl p-4 w-full">
                  {report.image?.s3_presiged && (
                    <img
                      src={report.image?.s3_presiged}
                      className="w-32 rounded-xl"
                    />
                  )}
                  {report.eye_laterality && (
                    <div className="flex gap-2 items-center">
                      <div className="text-title-small text-slate-600">
                        Eye Laterality:
                      </div>
                      <div className="text-title-small text-slate-600">
                        {report.eye_laterality}
                      </div>
                    </div>
                  )}
                  {report.diabetic_retinoptahy_risk && (
                    <div className="flex gap-2 items-center">
                      <div className="text-title-small text-slate-600">
                        Diabetic Retinoptahy Risk:
                      </div>
                      <div className="text-title-small text-slate-600">
                        {report.diabetic_retinoptahy_risk}
                      </div>
                    </div>
                  )}
                  {report.glaucoma_risk && (
                    <div className="flex gap-2 items-center">
                      <div className="text-title-small text-slate-600">
                        Glaucoma Risk:
                      </div>
                      <div className="text-title-small text-slate-600">
                        {report.glaucoma_risk}
                      </div>
                    </div>
                  )}
                  {report.diabetic_retinoptahy_risk_level !== null && (
                    <div className="flex gap-2 items-center">
                      <div className="text-title-small text-slate-600">
                        Diabetic Retinoptahy Risk Level:
                      </div>
                      <div className="text-title-small text-slate-600">
                        {report.diabetic_retinoptahy_risk_level}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </Modal>
    </BaseLayout>
  );
};

export default KioskDrJiviSession;
