import * as SERVICEs from "../services/Auth";

export const getOtp = (body) => {
  return SERVICEs.getOtp(body)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const submitOtp = (body) => {
  return SERVICEs.submitOtp(body)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const skipLoginApi = () => {
  return SERVICEs.skipLoginApi()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const resendOtpApi = (body) => {
  return SERVICEs.resendOtpApi(body)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserProfiles = () => {
  return SERVICEs.getUserProfiles()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateProfile = (id, payload) => {
  return SERVICEs.updateProfile(id, payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const createProfile = (payload) => {
  return SERVICEs.createProfile(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getUserDetails = (uid) => {
  return SERVICEs.getUserDetails(uid)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const validateReferral = (payload) => {
  return SERVICEs.validateReferral(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getShareableId = (payload) => {
  return SERVICEs.getShareableLink(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};
