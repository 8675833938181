import KioskHeader from "../../components/kiosk/header";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getDifferentialDiagnosis,
  getNextStepsAndTreatment,
} from "../../dataManager/Chat";
import { useEffect, useState, useMemo } from "react";
import bagIcon from "../../assets/images/bag.svg";
import doctorOrangeIcon from "../../assets/images/doctor-orange.svg";
import SecondaryButton from "../../subComponents/SecondaryButton";
import PrimaryButton from "../../subComponents/PrimaryButton";
import { Divider, Modal } from "antd";
import KioskLoader from "../../components/kiosk/loader";
import BaseLayout from "../../layout/BaseLayout";
import { useTranslation } from "react-i18next";
import orangeChevronRight from "../../assets/images/orange-chevron-right.svg";
import labReportOrange from "../../assets/images/lab-report-orange.svg";
import { INIT_COPILOT_SESSION } from "../../constants/BackendConstant";
import { openExternalLink } from "../../utilities/helper";
import { axiosInstance } from "../../utilities/configureAxios";

const KioskDrJiviNextSteps = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const sessionId = searchParams.get("sessionId");
  const index = parseInt(searchParams.get("index")) || 0;
  const [ddx, setDdx] = useState([]);
  const [nextSteps, setNextSteps] = useState(null);
  const [isTreatmentModalOpen, setIsTreatmentModalOpen] = useState(false);
  const [isInvestigationModalOpen, setIsInvestigationModalOpen] =
    useState(false);

  const getDdx = async () => {
    const ddxData = await getDifferentialDiagnosis(sessionId, {});
    setDdx(ddxData.data.data.diagnosis);
    return ddxData.data.data.diagnosis;
  };

  const getNextSteps = async (diagnosis) => {
    const payload = {
      differential_diagnosis: [diagnosis.name],
      session_id: sessionId,
      summary: diagnosis.reason,
      cache: true,
    };
    const nextSteps = await getNextStepsAndTreatment(payload, {});
    return nextSteps.data.data;
  };

  useEffect(() => {
    const loadNextSteps = async () => {
      const ddxArray = await getDdx();
      if (ddxArray.length > 0) {
        const nextSteps = await getNextSteps(ddxArray[index]);
        setNextSteps(nextSteps);
      }
    };

    loadNextSteps();
  }, []);

  const getConsolidatedTreatments = useMemo(() => {
    let treatments = [];
    if (nextSteps?.treatments) {
      Object.entries(nextSteps.treatments).map(([key, value]) => {
        treatments = treatments.concat(value);
      });
    }
    return treatments;
  }, [nextSteps]);

  const getTotalTreatmentsCount = useMemo(() => {
    let treatments = [];
    if (nextSteps?.treatments) {
      Object.entries(nextSteps.treatments).map(([key, value]) => {
        treatments = treatments.concat(value);
      });
    }
    return treatments.length;
  }, [nextSteps]);

  const getConsolidatedInvestigations = useMemo(() => {
    let investigations = [];
    if (nextSteps?.investigations) {
      Object.entries(nextSteps.investigations).map(([key, value]) => {
        investigations = investigations.concat(value);
      });
    }
    return investigations;
  }, [nextSteps]);

  const getTotalInvestigationsCount = useMemo(() => {
    let investigations = [];
    if (nextSteps?.investigations) {
      Object.entries(nextSteps.investigations).map(([key, value]) => {
        investigations = investigations.concat(value);
      });
    }
    return investigations.length;
  }, [nextSteps]);

  const onConsultDoctor = () => {
    axiosInstance
      .post(INIT_COPILOT_SESSION, {
        sessionType: "ONLINE",
        consumerSessionCode: nextSteps.display_code,
      })
      .then((response) => {
        const meetingLink = `${import.meta.env.VITE_COPILOT_URL}/public/meeting?sessionId=${response.data.data.sessionDetails[0].sessionId}`;
        navigate(`/meeting?link=${meetingLink}`);
      });
  };

  if (!nextSteps) {
    return <KioskLoader />;
  }

  return (
    <BaseLayout title={t("observations")}>
      <div className="max-h-full min-w-full">
        <div className="h-[80%] flex flex-col w-full gap-4 p-4 overflow-y-scroll">
          {ddx?.length > 0 && (
            <div className="flex flex-col gap-2 w-full bg-[#E9F2FF] rounded-xl p-4">
              <p className="text-primaryBlue text-xl font-medium">
                {ddx[index].name}
              </p>
              <p className="text-sm opacity-60">{ddx[index].reason}</p>
            </div>
          )}
          <div className="flex items-center rounded-xl border justify-between">
            <div className="flex items-center gap-4 p-4">
              <img src={doctorOrangeIcon} className="h-4" alt="Doctor" />
              <p className="text-title-medium">{t("consult_doctor")}</p>
            </div>
            <div
              className="py-2 px-4 rounded-xl border mr-4 cursor-pointer bg-primary text-white"
              onClick={onConsultDoctor}
            >
              <p className="text-title-medium">{t("start")}</p>
            </div>
          </div>
          {getTotalTreatmentsCount > 0 && (
            <div className="flex flex-col gap-4 w-full">
              <p className="text-sm font-medium">{t("treatments")}</p>
              <div
                className="flex flex-col gap-4 w-full bg-white rounded-xl p-4 border"
                onClick={() => setIsTreatmentModalOpen(true)}
              >
                {getConsolidatedTreatments
                  .slice(0, 3)
                  .map((treatment, index) => (
                    <div key={index} className="flex flex-col gap-4 w-full">
                      <div className="flex flex-row justify-between items-center w-full">
                        <div className="flex flex-col gap-1">
                          <div className="flex items-center gap-2">
                            <img
                              src={bagIcon}
                              alt="Treatment"
                              className="h-4"
                            />
                            <p className="text-sm font-medium">
                              {treatment.name}
                            </p>
                          </div>
                          {index == 0 && (
                            <p className="text-sm text-black text-opacity-50">
                              {treatment.content}
                            </p>
                          )}
                        </div>
                        <img src={orangeChevronRight} alt="Next" />
                      </div>
                      {index !== 2 && (
                        <div className="w-full h-[1px] bg-gradient-to-r from-primaryLight via-primary to-primaryLight " />
                      )}
                    </div>
                  ))}
                <SecondaryButton
                  customClass="w-full"
                  text={`${t("view_all")} (${getTotalTreatmentsCount})`}
                  onClick={() => setIsTreatmentModalOpen(true)}
                />
              </div>
            </div>
          )}
          {getTotalInvestigationsCount > 0 && (
            <div className="flex flex-col gap-4 w-full">
              <p className="text-sm font-medium">{t("lab_tests")}</p>
              <div
                className="flex flex-col gap-4 w-full bg-white rounded-xl p-4 border"
                onClick={() => setIsInvestigationModalOpen(true)}
              >
                {getConsolidatedInvestigations
                  .slice(0, 3)
                  .map((investigation, index) => (
                    <div key={index} className="flex flex-col gap-4 w-full">
                      <div className="flex flex-row justify-between items-center w-full">
                        <div className="flex flex-col gap-1">
                          <div className="flex items-center gap-2">
                            <img
                              src={labReportOrange}
                              alt="Treatment"
                              className="h-4"
                            />
                            <p className="text-sm font-medium">
                              {investigation.name}
                            </p>
                          </div>
                          {index == 0 && (
                            <p className="text-sm text-black text-opacity-50">
                              {investigation.content}
                            </p>
                          )}
                        </div>
                        <img src={orangeChevronRight} alt="Next" />
                      </div>
                      {index !== 2 && (
                        <div className="w-full h-[1px] bg-gradient-to-r from-primaryLight via-primary to-primaryLight " />
                      )}
                    </div>
                  ))}
                <SecondaryButton
                  customClass="w-full"
                  text={`${t("view_all")} (${getTotalInvestigationsCount})`}
                  onClick={() => setIsInvestigationModalOpen(true)}
                />
              </div>
            </div>
          )}
          <Modal
            open={isTreatmentModalOpen}
            footer={null}
            onCancel={() => setIsTreatmentModalOpen(false)}
            width={"90%"}
          >
            <div className="flex flex-col gap-6 w-full max-h-[90vh] overflow-y-scroll">
              <p className="text-base font-medium">{t("treatments")}</p>
              {getConsolidatedTreatments.map((treatment, index) => (
                <div key={index} className="flex flex-col gap-1">
                  <div className="flex items-center gap-2">
                    <img src={bagIcon} alt="Treatment" className="h-4" />
                    <p className="text-sm font-medium">{treatment.name}</p>
                  </div>
                  <p className="text-sm text-black text-opacity-50">
                    {treatment.content}
                  </p>
                </div>
              ))}
            </div>
          </Modal>
          <Modal
            open={isInvestigationModalOpen}
            footer={null}
            onCancel={() => setIsInvestigationModalOpen(false)}
            width={"90%"}
          >
            <div className="flex flex-col gap-6 w-full max-h-[90vh] overflow-y-scroll">
              <p className="text-base font-medium">{t("lab_tests")}</p>
              {getConsolidatedInvestigations.map((investigation, index) => (
                <div key={index} className="flex flex-col gap-1">
                  <div className="flex items-center gap-2">
                    <img src={bagIcon} alt="Treatment" className="h-4" />
                    <p className="text-sm font-medium">{investigation.name}</p>
                  </div>
                  <p className="text-sm text-black text-opacity-50">
                    {investigation.content}
                  </p>
                </div>
              ))}
            </div>
          </Modal>
        </div>
        <div className="mx-6">
          <PrimaryButton
            text={t("finish_session")}
            customClass="w-full mb-6"
            onClick={() => navigate("/")}
          />
        </div>
      </div>
    </BaseLayout>
  );
};

export default KioskDrJiviNextSteps;
