import KioskHeader from "../../components/kiosk/header";
import { useNavigate } from "react-router-dom";
import { getSessionToken } from "../../dataManager/Chat";

const KioskDrJiviHome = () => {
  const navigate = useNavigate();

  const startSession = async () => {
    let payload = {
      type: "GUIDED",
    };
    const res = await getSessionToken(payload);
    const sessionId = res.data.data.sessionId;

    navigate(`/kiosk/dr-jivi-session?sessionId=${sessionId}`);
  };

  return (
    <div className="w-full min-h-full justify-center items-center flex flex-col">
      <KioskHeader back={true} title="Dr Jivi Home" />
      <div className="grow flex flex-col justify-center items-center">
        <button
          onClick={() => {
            startSession();
          }}
        >
          Start Session
        </button>
      </div>
    </div>
  );
};

export default KioskDrJiviHome;
