import backIcon from "../../assets/images/black_right_arrow.svg";
import { useNavigate } from "react-router-dom";
import { Switch } from "antd";
import globeIcon from "../../assets/images/globe.svg";
import { useState } from "react";

const KioskHeader = ({ back, title, onVoiceToggle, onLanguageChange }) => {
  const navigate = useNavigate();
  const [voiceMode, setVoiceMode] = useState(true);

  const onChange = (checked) => {
    setVoiceMode(checked);
    onVoiceToggle && onVoiceToggle(checked);
  };

  return (
    <div className="flex flex-row justify-between items-center w-full py-4 px-2">
      <div className="grow flex flex-row items-center">
        {back && (
          <button className="flex items-center" onClick={() => navigate(-1)}>
            <img src={backIcon} className="rotate-180 h-10 w-10" alt="Back" />
          </button>
        )}
        {title && <p className="font-medium text-lg">{title}</p>}
      </div>
    </div>
  );
};

export default KioskHeader;
