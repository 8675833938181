import * as APIs from "../constants/BackendConstant";
import { axiosInstance } from "../utilities/configureAxios";
import * as ApiResponse from "../constants/ApiResponse";
import { getMockValue } from "../utilities/helper";

export const getSessionToken = (payload = {}) => {
  let url = APIs.GET_SESSION_TOKEN;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.session_token);
  }
  return axiosInstance.post(url, payload);
};

export const editSessionData = (sessionId, payload) => {
  let url = APIs.EDIT_SESSION_DATA.replace(":sessionId", sessionId);
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.edit_session_data);
  }
  return axiosInstance.put(url, payload);
};

export const getTopSymptoms = () => {
  let url = APIs.GET_TOP_SYMPTOMS;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.top_symptoms);
  }
  return axiosInstance.get(url);
};

export const getTrendingIssues = () => {
  let url = APIs.GET_TRENDING_ISSUES;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.trending_issues);
  }
  return axiosInstance.get(url);
};

export const getSearchSymptoms = (key) => {
  let url = APIs.GET_SEARCH_SYMPTOMS.replace(":search_key", key);
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.search_result);
  }
  return axiosInstance.get(url);
};

export const guidedChat = (data, options) => {
  let url = APIs.GUIDED_CHAT;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.guided_chat);
  }
  let extraOptions = {};
  if (options.timeout) {
    extraOptions.timeout = options.timeout;
  }
  return axiosInstance.post(url, data, extraOptions);
};

export const freeChat = (data) => {
  let url = APIs.FREE_CHAT;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.free_chat);
  }
  return axiosInstance.post(url, data);
};

export const getSummary = (sessionId, queryParams) => {
  let url = APIs.GET_SUMMARY;
  let payload = {
    session_id: sessionId,
    enable_history: false,
    user_input: "",
  };

  let queryParamsArray = [];

  if (queryParams.shareableId) {
    queryParamsArray.push(`shareableId=${queryParams.shareableId}`);
  }

  if (queryParams.entityType) {
    queryParamsArray.push(`entityType=${queryParams.entityType}`);
  }

  if (queryParamsArray.length > 0) {
    url = url + "?" + queryParamsArray.join("&");
  }

  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_summary);
  }
  return axiosInstance.post(url, payload);
};

export const getDifferentialDiagnosis = (sessionId, queryParams) => {
  let url = APIs.GET_DIFFERENTIAL_DIAGNOSIS;
  let payload = {
    session_id: sessionId,
    enable_history: false,
    user_input: "",
  };

  let queryParamsArray = [];

  if (queryParams.shareableId) {
    queryParamsArray.push(`shareableId=${queryParams.shareableId}`);
  }

  if (queryParams.entityType) {
    queryParamsArray.push(`entityType=${queryParams.entityType}`);
  }

  if (queryParamsArray.length > 0) {
    url = url + "?" + queryParamsArray.join("&");
  }

  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_differential_diagnosis);
  }
  return axiosInstance.post(url, payload);
};

export const getTranscribe = (data) => {
  let url = APIs.GET_TRANSCRIBE;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_transcribe);
  }
  return axiosInstance.post(url, data);
};

export const chatHistory = (page = 1, speciality, type, limit) => {
  let url = APIs.SESSION_LIST.replace(":page_number", page);
  if (speciality) {
    url = url + "&speciality=" + speciality;
  }
  if (type) {
    url = url + "&sessionType=" + type;
  }
  if (limit) {
    url = `${url}&limit=${limit}`;
  }

  if (getMockValue()) {
    return Promise.resolve(ApiResponse.chat_history);
  }
  return axiosInstance.get(url);
};

export const sessionDetails = (sessionId, queryParams) => {
  let url = APIs.SESSION_DETAILS.replace(":session_id", sessionId);

  let queryParamsArray = [];

  if (queryParams.shareableId) {
    queryParamsArray.push(`shareableId=${queryParams.shareableId}`);
  }

  if (queryParams.entityType) {
    queryParamsArray.push(`entityType=${queryParams.entityType}`);
  }

  if (queryParamsArray.length > 0) {
    url = url + "?" + queryParamsArray.join("&");
  }

  if (getMockValue()) {
    return Promise.resolve(ApiResponse.session_details);
  }
  return axiosInstance.get(url);
};

export const updateVitals = (data, sessionId) => {
  let url = APIs.UPDATE_VITALS.replace(":session_id", sessionId);
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.update_vitals);
  }
  return axiosInstance.post(url, { vitals: data });
};

export const getConversation = (sessionId) => {
  let url = APIs.GET_CONVERSATION.replace(":session_id", sessionId);
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_conversation);
  }
  return axiosInstance.get(url);
};

export const getAudioFromText = (text, options) => {
  let url = APIs.GET_AUDIO_TEXT;
  let payload = {
    text: text,
  };
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_text_audio);
  }
  let extraOptions = {};
  if (options.timeout) {
    extraOptions.timeout = options.timeout;
  }
  return axiosInstance.post(url, payload, extraOptions);
};

export const editChat = (sessionId, messageId) => {
  let payload = {
    session_id: sessionId,
    msg_id: messageId,
  };
  let url = APIs.EDIT_CHAT;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.edit_chat);
  }
  return axiosInstance.put(url, payload);
};

export const uploadFile = (payload) => {
  let url = APIs.DOC_UPLOAD;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.upload_doc);
  }
  return axiosInstance.post(url, payload, { headers: { uid: "" } });
};

export const getDocsFromSessionId = (sessionId) => {
  let url = APIs.GET_DOC_FROM_SESSION_ID.replace(":session_id", sessionId);
  return axiosInstance.get(url);
};

export const uploadInternalFile = (payload) => {
  let url = APIs.DOC_INTERNAL_UPLOAD;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.upload_internal_doc);
  }
  return axiosInstance.post(url, payload, {
    headers: { uid: "", "Content-Type": "multipart/form-data" },
  });
};

export const getTreatments = (payload) => {
  let url = APIs.GET_TREATMENTS;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.treatments);
  }
  return axiosInstance.post(url, payload);
};

export const analyseReports = (payload) => {
  let url = APIs.IMAGE_ANALYSIS;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.analyse_reports);
  }
  return axiosInstance.post(url, payload, { headers: { uid: "" } });
};

export const getChatMessages = (sessionType, sessionId) => {
  let url = APIs.GET_CHAT_MESSAGES.replace(":sessionType", sessionType).replace(
    ":sessionID",
    sessionId
  );
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.analyse_reports);
  }
  return axiosInstance.get(url, {
    headers: { uid: "" },
  });
};

export const getDocs = (payload) => {
  let url = APIs.GET_DOCS;
  let queryParams = [];

  if (payload.docType) {
    queryParams.push(`docType=${payload.docType}`);
  }

  if (payload.uploadReferenceId) {
    queryParams.push(`uploadReferenceId=${payload.uploadReferenceId}`);
  }

  if (payload.analysisType) {
    queryParams.push(`analysisType=${payload.analysisType}`);
  }

  if (payload.shareableId) {
    queryParams.push(`shareableId=${payload.shareableId}`);
  }

  if (queryParams.entityType) {
    queryParams.push(`entityType=${queryParams.entityType}`);
  }

  if (queryParams.length > 0) {
    url = url + "?" + queryParams.join("&");
  }

  if (getMockValue()) {
    return Promise.resolve(ApiResponse.analyse_reports);
  }
  return axiosInstance.get(url, {
    headers: { uid: "" },
  });
};

export const getSecondOpinionChat = (payload) => {
  let url = APIs.GET_SECOND_OPINION_CHAT;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_second_opinion_chat);
  }
  return axiosInstance.post(url, payload);
};

export const getSecondOpinionDDx = (payload, queryParams) => {
  let url = APIs.GET_SECOND_OPINION_DDX;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_second_opinion_ddx);
  }

  let queryParamsArray = [];

  if (queryParams.shareableId) {
    queryParamsArray.push(`shareableId=${queryParams.shareableId}`);
  }

  if (queryParams.entityType) {
    queryParamsArray.push(`entityType=${queryParams.entityType}`);
  }

  if (queryParamsArray.length > 0) {
    url = url + "?" + queryParamsArray.join("&");
  }

  return axiosInstance.post(url, payload);
};

export const getDiseasesList = (query, limit) => {
  let url = APIs.GET_DISEASES_LIST;
  let queryParams = [];
  if (query) {
    queryParams.push(`query=${query}`);
  }
  if (limit) {
    queryParams.push(`limit=${limit}`);
  }
  if (queryParams.length > 0) {
    url = url + "?" + queryParams.join("&");
  }
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_diseases_list);
  }
  return axiosInstance.get(url);
};

export const getTreatmentsAndNextSteps = (payload, queryParams) => {
  let url = APIs.GET_TREATMENTS_AND_NEXT_STEPS;

  let queryParamsArray = ["getFromCache=True"];

  if (queryParams.shareableId) {
    queryParamsArray.push(`shareableId=${queryParams.shareableId}`);
  }

  if (queryParams.entityType) {
    queryParamsArray.push(`entityType=${queryParams.entityType}`);
  }

  if (queryParamsArray.length > 0) {
    url = url + "?" + queryParamsArray.join("&");
  }

  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_treatments_and_next_steps);
  }
  return axiosInstance.post(url, payload);
};

export const editSession = (payload) => {
  let url = APIs.EDIT_SESSION;

  return axiosInstance.put(url, payload);
};

export const getSecondOpinionHistory = (page = 1, type = "SECOND_OPINION") => {
  let url = APIs.SESSION_LIST.replace(":page_number", page);
  url = url + "&sessionType=" + type;
  return axiosInstance.get(url);
};

export const followupChat = (payload) => {
  let url = APIs.FOLLOW_UP_CHAT;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.follow_up_chat);
  }
  return axiosInstance.post(url, payload);
};
