import React from "react";

const BarsLoader = ({
  color = "black",
  size = "35px",
  speed = "1s",
  enabled = true,
}) => {
  const containerStyle = {
    "--uib-color": color,
    "--uib-size": size,
    "--uib-speed": speed,
    "--uib-stroke": `calc(${size} / 5)`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "var(--uib-size)",
    height: "calc(var(--uib-size) * 0.9)",
  };

  const barStyle = {
    width: "var(--uib-stroke)",
    height: "100%",
    backgroundColor: "var(--uib-color)",
    transition: "transform 0.3s ease, background-color 0.3s ease",
    borderRadius: "8px",
  };

  const keyframes = `
    @keyframes grow {
      0%, 100% {
        transform: scaleY(0.3);
      }
      50% {
        transform: scaleY(1);
      }
    }
  `;

  return (
    <div style={containerStyle} className="container">
      <style>
        {keyframes}
        {`
          .bar.animated:nth-child(1) {
            animation: grow var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.40) infinite;
          }
          .bar.animated:nth-child(2) {
            animation: grow var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.3) infinite;
          }
          .bar.animated:nth-child(3) {
            animation: grow var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.2) infinite;
          }
          .bar.animated:nth-child(4) {
            animation: grow var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.1) infinite;
          }
          .bar.flat {
            transform: scaleY(0.3);
          }
        `}
      </style>
      {Array.from({ length: 3 }).map((_, index) => (
        <div
          key={index}
          className={`bar ${enabled ? "animated" : "flat"}`}
          style={barStyle}
        ></div>
      ))}
    </div>
  );
};

export default BarsLoader;
