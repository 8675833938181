import {
  getAudioFromText,
  getConversation,
  getSessionToken,
  getTranscribe,
  guidedChat,
  uploadFile,
} from "../dataManager/Chat";
import { blobToBase64, fileToBase64, generateRandomString } from "./helper";

const guidedChatTimeoutDuration = 25000;

export const generateSessionIdAndNavigate = function (type, specialization) {
  return new Promise(function (resolve, reject) {
    let payload = {
      type: type,
    };
    if (specialization) {
      payload.speciality = specialization;
    }

    getSessionToken(payload)
      .then((response) => {
        resolve(response.data.data.sessionId);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const checkAudioPermission = function () {
  return new Promise(function (resolve, reject) {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {
        const tracks = stream.getTracks();
        tracks.forEach(function (track) {
          track.stop();
        });
        resolve(true);
      })
      .catch(function (err) {
        reject(err);
      });
  });
};

export const getConversationsApi = (sessionId) => {
  return new Promise(function (resolve, reject) {
    getConversation(sessionId)
      .then((res) => {
        resolve(res.data.data.messages);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAudio = (text) => {
  return new Promise(function (resolve, reject) {
    getAudioFromText(text)
      .then((response) => {
        if (response?.data?.data?.audio) {
          resolve(response.data.data.audio);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const guidedApiCall = (payload) => {
  return new Promise(function (resolve, reject) {
    guidedChat(payload, { timeout: guidedChatTimeoutDuration })
      .then((response) => {
        resolve(response.data.data.response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTextFromSpeech = (blob, options = {}) => {
  return new Promise(function (resolve, reject) {
    blobToBase64(blob, function (base64String) {
      getTranscribe(
        {
          audio: base64String,
        },
        options
      )
        .then((response) => {
          resolve(response.data.data.text);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};

export const uploadUsingFile = (fileList, type, imageAnalysisType) => {
  return new Promise(async function (resolve, reject) {
    if (fileList.length > 0) {
      let uploadedImageFiles = [];
      for (let index = 0; index < fileList.length; index++) {
        await fileToBase64(fileList[index].originFileObj)
          .then((base64String) => {
            uploadedImageFiles.push({
              key: generateRandomString(),
              doc: base64String,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
      let otherPayload = {
        type: type,
        imageAnalysisType: "DERMA",
      };

      otherPayload.docs = uploadedImageFiles;
      let fileObjects = [];
      uploadFile(otherPayload)
        .then((response) => {
          let fileData = response.data.data.uploadDetails;
          Object.keys(fileData).forEach((file) => {
            fileObjects.push(fileData[file]);
          });
          resolve(fileObjects);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve([]);
    }
  });
};
