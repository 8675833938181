import * as APIs from "../constants/BackendConstant";
import { axiosInstanceDsJavris } from "../utilities/configureAxiosDsJarvis";
import * as ApiResponse from "../constants/ApiResponse";
import { getMockValue } from "../utilities/helper";

export const getScreeningChat = (payload) => {
  let url = APIs.GET_SCREENING_CHAT;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_screening_chat);
  }
  return axiosInstanceDsJavris.post(url, payload);
};

export const getScreeningDDX = (payload) => {
  let url = APIs.GET_SCREENING_DDX;
  if (getMockValue()) {
    return Promise.resolve(ApiResponse.get_screening_ddx);
  }
  return axiosInstanceDsJavris.post(url, payload);
};
