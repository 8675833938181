import KioskHeader from "../../components/kiosk/header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getDifferentialDiagnosis } from "../../dataManager/Chat";
import { useEffect, useState } from "react";
import { WarningOutlined } from "@ant-design/icons";
import hero from "../../assets/images/ddx/hero.png";
import next_button from "../../assets/images/ddx/next_button.svg";
import next_button_blue from "../../assets/images/ddx/next_button_blue.svg";
import { useTranslation } from "react-i18next";
import KioskLoader from "../../components/kiosk/loader";
import BaseLayout from "../../layout/BaseLayout";
import PrimaryButton from "../../subComponents/PrimaryButton";
import LoadingPage from "../LoadingPage";

const KioskDrJiviDdx = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const sessionId = searchParams.get("sessionId");
  const [ddx, setDdx] = useState([]);
  const [ogDiagnosis, setOgDiagnosis] = useState([]);

  const getDdx = async () => {
    const ddxData = await getDifferentialDiagnosis(sessionId, {});
    setDdx(ddxData.data.data.diagnosis);
    setOgDiagnosis(ddxData.data.data.original.diagnosis);
    return ddxData.data.data.diagnosis;
  };

  useEffect(() => {
    getDdx();
  }, []);

  const getLikeliness = (value) => {
    if (value >= 67) {
      return t("most_likely");
    }
    if (value >= 34) {
      return t("likely");
    }
    return t("unlikely");
  };

  const getSevereChip = (emergency) => {
    if (checkIfEmergency(emergency)) {
      return (
        <div className="w-fit px-3.5 py-1.5 bg-pink-100 text-red-500 rounded text-center text-body-medium capitalize flex gap-2 justify-center items-center">
          <WarningOutlined />
          {t("serious")}
        </div>
      );
    } else {
      return null;
    }
  };

  const checkIfEmergency = (emergency) => {
    return ["high", "very high"].includes(emergency.toLowerCase());
  };

  const onGoToNextSteps = (index) => {
    navigate(`/kiosk/dr-jivi-next-steps?sessionId=${sessionId}&index=${index}`);
  };

  if (ddx.length === 0) {
    return <LoadingPage />;
  }

  return (
    <BaseLayout title={t("observations")}>
      <div className="max-h-full">
        <div className="h-[80%] flex flex-col gap-10 grow p-4 overflow-scroll">
          {ddx.map((diagnosis, index) =>
            index === 0 ? (
              <div
                key={index}
                className="w-full rounded-xl shadow border border-gray-300"
              >
                <div
                  className="bg-primaryBlue w-full min-h-72 rounded-xl border-b border-gray-300 p-4 flex flex-col gap-4 justify-evenly items-start"
                  style={{
                    backgroundImage: `url(${hero})`,
                    backgroundPosition: "right top",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                  }}
                  onClick={() => onGoToNextSteps(index)}
                >
                  <div className="w-2/3 text-white text-title-large">
                    {diagnosis.name !== ogDiagnosis[index].name
                      ? diagnosis.name + " (" + ogDiagnosis[index].name + ")"
                      : diagnosis.name}
                  </div>
                  <div className="flex gap-4 flex-wrap">
                    <div className="w-fit px-3.5 py-1.5 bg-white rounded">
                      <div className="text-center text-rose-500 text-body-medium">
                        {getLikeliness(diagnosis.actual_conf)}
                      </div>
                    </div>
                    {getSevereChip(diagnosis.emergency)}
                  </div>
                  <div className="w-3/4 opacity-80 text-white text-content">
                    {diagnosis.description}
                  </div>
                  <div className="w-full rounded-bl-xl rounded-br-xl flex flex-row items-center mt-1">
                    <div className="text-white font-medium text-20 font-b">
                      {t("next_steps")}
                    </div>
                    <img
                      src={next_button}
                      alt="next button"
                      className="h-10 ml-4 cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={index}
                className="bg-white rounded-xl shadow border border-gray-300 w-full p-4 flex flex-col gap-5"
                onClick={() => onGoToNextSteps(index)}
              >
                <div className="w-full text-primaryBlue text-title-large">
                  {diagnosis.name !== ogDiagnosis[index].name
                    ? diagnosis.name + " (" + ogDiagnosis[index].name + ")"
                    : diagnosis.name}
                </div>
                <div className="flex gap-4 flex-wrap">
                  <div className="w-fit px-3.5 py-1.5 bg-primaryLight rounded justify-center items-center gap-2.5 inline-flex">
                    <div className="text-center text-primary text-body-medium">
                      {getLikeliness(diagnosis.actual_conf)}
                    </div>
                  </div>
                  {getSevereChip(diagnosis.emergency)}
                </div>
                <div className="w-full opacity-80 text-black text-content">
                  {diagnosis.description}
                </div>
                <img
                  src={next_button_blue}
                  alt="next button blue"
                  className="w-8 h-8 cursor-pointer"
                />
              </div>
            )
          )}
        </div>
        <div className="mx-6">
          <PrimaryButton
            text={t("done")}
            customClass="w-full mb-6"
            onClick={() => navigate("/")}
          />
        </div>
      </div>
    </BaseLayout>
  );
};

export default KioskDrJiviDdx;
