import { useState, useEffect, useRef } from "react";
import jiviLogo from "../assets/images/logo.svg";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { getTextFromSpeech } from "../utilities/apiHelper";
import { getAudioFromText } from "../dataManager/Chat";
import audioIconOrange from "../assets/images/audio-orange.svg";
import audioIconBlue from "../assets/images/audio-blue.svg";
import sendBlueIcon from "../assets/images/send-blue.svg";
import { Radio, Switch } from "antd";
import PrimaryButton from "../subComponents/PrimaryButton";
import { useTranslation } from "react-i18next";
import BaseLayout from "../layout/BaseLayout";
import { Player } from "@lottiefiles/react-lottie-player";
import LoadingJson from "../assets/lotties/loading.json";
import userBlue from "../assets/images/user-blue.svg";
import { optionsList } from "./Options";
import { getScreeningChat } from "../dataManager/Screening";
import resumeIcon from "../assets/images/resume.svg";
import pauseIcon from "../assets/images/pause.svg";

const workletCode = `
class AudioProcessor extends AudioWorkletProcessor {
    process(inputs, outputs, parameters) {
        const input = inputs[0];
        const channel = input[0];
        
        if (channel) {
            // Convert Float32Array to Int16Array
            const pcmData = new Int16Array(channel.length);
            for (let i = 0; i < channel.length; i++) {
                const sample = Math.max(-1, Math.min(1, channel[i]));
                pcmData[i] = sample < 0 ? sample * 0x8000 : sample * 0x7fff;
            }
            
            // Send the PCM data to the main thread
            this.port.postMessage(pcmData.buffer, [pcmData.buffer]);
        }
        
        return true; // Keep the processor running
    }
}

registerProcessor('audio-processor', AudioProcessor);
`;

const ScreeningChatV2 = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [voiceMode, setVoiceMode] = useState(true);
  const questionsRef = useRef([]);
  const currentQuestionRef = useRef({
    text: "",
    b64: "",
    meta: {},
    index: 0,
  });
  const [quickLinks, setQuickLinks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const optionsRef = useRef({});
  const [lastHumanMessage, setLastHumanMessage] = useState("");
  const [responses, setResponses] = useState({});

  const videoRef = useRef(null);
  const videoPlayerRef = useRef(null);
  const audioRef = useRef(null);
  const audioPlayerRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const [paused, setPaused] = useState(false);
  const audioContextRef = useRef(null);
  const workletNodeRef = useRef(null);
  const inputRef = useRef(null);
  const socketRef = useRef(null);
  const streamRef = useRef(null);

  const navigate = useNavigate();

  const getVideoStream = async () => {
    if (!optionsRef.current["jivi-flow-camera"]) {
      return;
    }
    try {
      // Get video stream
      videoRef.current = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });
      if (videoPlayerRef.current) {
        videoPlayerRef.current.srcObject = videoRef.current;
      }
    } catch (err) {
      console.error("Error accessing media devices:", err);
    }
  };

  const getAudioStream = async () => {
    try {
      // Get audio stream
      const audioStream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true,
      });
      if (audioStream) {
        audioRef.current = audioStream;
        return audioStream;
      }
    } catch (err) {
      console.error("Error accessing media devices:", err);
    }
  };

  const releaseAudioStream = () => {
    if (audioRef.current) {
      audioRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  const releaseVideoStream = () => {
    if (videoRef.current) {
      videoRef.current.getTracks().forEach((track) => track.stop());
    }

    if (videoPlayerRef.current) {
      videoPlayerRef.current.srcObject = null;
    }
  };

  const cleanup = () => {
    if (workletNodeRef.current) {
      workletNodeRef.current.disconnect();
      workletNodeRef.current = null;
    }

    if (inputRef.current) {
      inputRef.current.disconnect();
      inputRef.current = null;
    }

    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.close();
      socketRef.current = null;
    }

    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
  };

  useEffect(() => {
    const initialState = optionsList.reduce((acc, option) => {
      if (localStorage.getItem(option.key) === null) {
        localStorage.setItem(option.key, true);
      }
      acc[option.key] = localStorage.getItem(option.key) === "true";
      return acc;
    }, {});

    optionsRef.current = initialState;

    if (voiceMode) {
      getVideoStream();
    }

    loadQuestions();

    return () => {
      releaseAudioStream();
      releaseVideoStream();
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (voiceMode) {
      getVideoStream();
    } else {
      releaseAudioStream();
      releaseVideoStream();
      stopAudio();
      cleanup();
      stopRecording(true);
    }
  }, [voiceMode]);

  const loadQuestions = () => {
    const questionsFromLocation = location?.state?.questions ?? [];
    if (!questionsFromLocation.length) {
      navigate("/screening", { replace: true });
    }
    questionsRef.current = questionsFromLocation;

    askQuestion(
      questionsFromLocation[0].header,
      questionsFromLocation[0],
      0,
      questionsFromLocation[0].audioBase64
    );
  };

  const initAudioWorklet = async (audioContext) => {
    const blob = new Blob([workletCode], { type: "application/javascript" });
    const url = URL.createObjectURL(blob);
    await audioContext.audioWorklet.addModule(url);
    URL.revokeObjectURL(url);
  };

  const startRecording = async () => {
    if (!optionsRef.current["jivi-flow-voice"]) {
      return;
    }

    socketRef.current = new WebSocket(import.meta.env.VITE_WS_SPEECH_URL);
    socketRef.current.binaryType = "arraybuffer";

    socketRef.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      const event_type = data.result.speech_event_type;
      if (!optionsRef.current["jivi-flow-auto-stop"]) {
        return;
      }
      if (event_type === 3) {
        stopRecording();
      }
    };

    await getAudioStream();
    mediaRecorderRef.current = new MediaRecorder(audioRef.current);

    mediaRecorderRef.current.ondataavailable = (e) => {
      const audioBlob = e.data;
      processAudio(audioBlob);
    };

    audioContextRef.current = new (window.AudioContext ||
      window.webkitAudioContext)();
    await initAudioWorklet(audioContextRef.current);
    inputRef.current = audioContextRef.current.createMediaStreamSource(
      audioRef.current
    );

    workletNodeRef.current = new AudioWorkletNode(
      audioContextRef.current,
      "audio-processor"
    );
    inputRef.current.connect(workletNodeRef.current);
    workletNodeRef.current.connect(audioContextRef.current.destination);
    workletNodeRef.current.port.onmessage = (event) => {
      if (socketRef.current?.readyState === WebSocket.OPEN) {
        socketRef.current.send(event.data);
      }
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);
  };

  const stopRecording = (forceStop = false) => {
    if (!mediaRecorderRef.current) return;
    if (forceStop) {
      mediaRecorderRef.current.ondataavailable = null;
    }
    mediaRecorderRef.current.stop();
    cleanup();
    setIsRecording(false);
    releaseAudioStream();
  };

  const processAudio = async (audioBlob) => {
    const index = currentQuestionRef.current.index;
    sendMessageScreening();
    const text = await getTextFromSpeech(audioBlob);
    handleResponse(text, index);
  };

  const playAudio = (src, startRecordingAfterEnded = false) => {
    if (!optionsRef.current["jivi-flow-speaker"]) {
      startRecordingAfterEnded && startRecording();
      return;
    }

    const audioPlayer = audioPlayerRef.current;
    audioPlayer.src = src;
    audioPlayer.play();
    setIsPlaying(true);

    if (voiceMode) {
      audioPlayer.onended = () => {
        setIsPlaying(false);
        startRecordingAfterEnded && startRecording();
        audioPlayer.onended = null;
      };
    }
  };

  const stopAudio = () => {
    const audioPlayer = audioPlayerRef.current;
    audioPlayer.onended = null;
    audioPlayer.pause();
    setIsPlaying(false);
  };

  const askQuestion = async (question, meta, index, audioBase64 = null) => {
    let base64 = audioBase64;
    if (!audioBase64) {
      setLoading(true);
      base64 = await getAudioFromText(question);
      setLoading(false);
    }

    currentQuestionRef.current = {
      text: question,
      meta: meta,
      index: index,
      b64: base64,
    };

    setQuickLinks(meta.choices || []);
    playAudio(base64.data.data.audio, true);
  };

  const handleResponse = (message, index) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [index]: message,
    }));
  };

  const sendMessageScreening = () => {
    const nextQuestionIndex = currentQuestionRef.current.index + 1;
    const nextQuestion = questionsRef.current[nextQuestionIndex];

    if (!nextQuestion) {
      return;
    }

    askQuestion(
      nextQuestion.header,
      nextQuestion,
      nextQuestionIndex,
      nextQuestion.audioBase64
    );
  };

  useEffect(() => {
    if (Object.keys(responses).length === questionsRef.current.length) {
      handleSubmit();
    }
  }, [responses]);

  const handleSubmit = async () => {
    setLoading(true);
    const res = await getScreeningChat({
      symptoms: Object.keys(responses).map((key) => {
        return {
          header: questionsRef.current[key].header,
          code:
            typeof responses[key] == typeof []
              ? responses[key]
              : [responses[key]],
          symptom_code: null,
        };
      }),
      diagnosis: location?.state?.diagnosisCode,
      session_id: location?.state?.sessionId ?? "",
      session_type: "SCREENING",
    });
    setLoading(false);
    navigate(`/screening-details?sessionId=${sessionId}`, {
      replace: true,
    });
  };

  return (
    <BaseLayout
      title={t("talk_to_jivi")}
      backLink="/"
      extra={
        <div className="flex gap-2 items-center">
          <p className="text-primary text-sm font-medium">Voice</p>
          <Switch
            className=""
            defaultChecked={voiceMode}
            onChange={(checked) => {
              setVoiceMode(checked);
            }}
          />
          <div>
            {paused ? (
              <img
                src={resumeIcon}
                alt="Resume"
                className="h-4 cursor-pointer"
                onClick={() => {
                  setPaused(false);
                  playAudio(currentQuestionRef.current.b64);
                }}
              />
            ) : (
              <img
                src={pauseIcon}
                alt="Pause"
                className="h-4 cursor-pointer"
                onClick={() => {
                  setPaused(true);
                  stopAudio();
                  stopRecording(true);
                }}
              />
            )}
          </div>
        </div>
      }
      showLanguage={false}
      languageState={location.state}
    >
      <div className="h-full w-full absolute -z-50 bg-gradient-to-bl from-primaryLight from-10% via-white via-50% to-white to-100%" />
      <audio ref={audioPlayerRef} className="hidden">
        <source src="https://dl.espressif.com/dl/audio/ff-16b-2c-44100hz.mp3" />
      </audio>
      <div className={"grow flex flex-col " + (voiceMode ? "" : "hidden")}>
        <div className="basis-1/2 max-h-[40vh]">
          <div className="flex flex-row gap-12 h-full px-12 py-12">
            <div className="basis-1/2 flex flex-col gap-4 h-full">
              <div
                className={
                  "flex justify-center items-center w-full gap-2 " +
                  (isPlaying ? "visible" : "invisible")
                }
              >
                <img src={audioIconOrange} alt="Audio Icon" className="h-4" />
                <p className="font-medium text-sm">{t("speaking")}</p>
              </div>
              <div
                className={
                  "rounded-xl border flex justify-center items-center grow shadow-[5px_5px_70px_5px_rgba(0,0,0,0.1)] " +
                  (isPlaying ? "shadow-primary" : "shadow-none")
                }
              >
                <img className="w-1/2" src={jiviLogo} alt="Jivi Logo" />
              </div>
            </div>
            <div className="basis-1/2 flex flex-col gap-4 h-full">
              <div
                className={
                  "flex justify-center items-center w-full gap-2 " +
                  (isRecording ? "visible" : "invisible")
                }
              >
                <img src={audioIconBlue} alt="Audio Icon" className="h-4" />
                <p className="font-medium text-sm">{t("speaking")}</p>
              </div>
              <div
                className={
                  "rounded-xl border-2 grow relative shadow-[5px_5px_70px_5px_rgba(0,0,0,0.1)] " +
                  (isRecording
                    ? "shadow-primaryBlue border-primaryBlue"
                    : "shadow-none")
                }
              >
                {isRecording && (
                  <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 z-10">
                    <img
                      src={sendBlueIcon}
                      alt="Send Icon"
                      className="h-12 cursor-pointer"
                      onClick={() => stopRecording()}
                    />
                  </div>
                )}
                <video
                  ref={videoPlayerRef}
                  autoPlay
                  muted
                  className="h-full object-cover rounded-xl"
                  style={{ transform: "scaleX(-1)" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="basis-full max-h-[50vh] bg-gradient-to-b from-primaryLight to-white p-6 border-t border-primary border-opacity-50 overflow-y-scroll bg-red-500">
          {loading && (
            <div className="flex flex-col w-full justify-center items-center">
              <Player className="w-full" src={LoadingJson} autoplay loop />
              <p className="font-medium text-sm opacity-60 text-black">
                {t("processing") + "..."}
              </p>
            </div>
          )}
          {!loading && (
            <div className="flex flex-col justify-center items-center gap-6">
              {lastHumanMessage &&
                optionsRef.current["jivi-flow-transcription"] && (
                  <div className="w-full flex justify-end">
                    <div className="max-w-[75%] px-4 py-2 rounded-xl border border-primaryBlue flex flex-row gap-4 items-center">
                      <p className="text-md font-medium">{lastHumanMessage}</p>
                      <img src={userBlue} alt="User" className="h-8" />
                    </div>
                  </div>
                )}
              <p className="font-medium text-lg text-center">
                {currentQuestionRef.current.text}
              </p>
              <div className="flex flex-row gap-4 flex-wrap justify-center">
                {quickLinks.map((link, index) => (
                  <div
                    key={index}
                    className="border border-primary py-1 px-2 rounded-xl cursor-pointer"
                    onClick={() => {
                      stopRecording(true);
                      stopAudio();
                      handleResponse(link, currentQuestionRef.current.index);
                      sendMessageScreening();
                    }}
                  >
                    <p className="text-md grow text-nowrap">{link}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      {!voiceMode && (
        <div className="grow flex flex-col mt-8 mx-4">
          {loading && (
            <div className="flex flex-col w-full justify-center items-center">
              <Player className="w-full" src={LoadingJson} autoplay loop />
              <p className="font-medium text-sm opacity-60 text-black">
                {t("processing") + "..."}
              </p>
            </div>
          )}
          {!loading && (
            <div className="flex flex-col gap-4">
              {lastHumanMessage &&
                optionsRef.current["jivi-flow-transcription"] && (
                  <div className="w-full flex justify-end">
                    <div className="max-w-[75%] px-4 py-2 rounded-xl border border-primaryBlue flex flex-row gap-4 items-center">
                      <p className="text-md font-medium">{lastHumanMessage}</p>
                      <img src={userBlue} alt="User" className="h-8" />
                    </div>
                  </div>
                )}
              <div className="flex flex-row gap-6 items-center">
                <img src={jiviLogo} alt="Jivi Logo" className="h-10" />
                <p className="font-medium text-xl">
                  {currentQuestionRef.current.text}
                </p>
              </div>
              <div className="flex flex-col gap-6 my-8">
                {quickLinks.length > 0 &&
                  quickLinks.map((link, index) => (
                    <div
                      key={index}
                      className="py-2 px-4 rounded-xl cursor-pointer shadow-md flex items-center bg-white"
                      onClick={() => {
                        stopAudio();
                        handleResponse(link, currentQuestionRef.current.index);
                        sendMessageScreening();
                      }}
                    >
                      <Radio>
                        <p className="text-lg">{link}</p>
                      </Radio>
                    </div>
                  ))}
                {quickLinks.length === 0 && (
                  <div className="flex flex-col gap-2">
                    <p className="text-sm text-center">
                      {t("please_describe_your_symptoms_in_detail")}
                    </p>
                    <textarea
                      value={textareaValue}
                      onChange={(e) => setTextareaValue(e.target.value)}
                      className="border border-primary p-2 rounded-xl"
                    />
                    <PrimaryButton
                      text={t("send")}
                      onClick={() => {
                        stopAudio();
                        handleResponse(
                          textareaValue,
                          currentQuestionRef.current.index
                        );
                        sendMessageScreening();
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </BaseLayout>
  );
};

export default ScreeningChatV2;
