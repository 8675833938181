import { Drawer, Modal } from "antd";
import { useTranslation } from "react-i18next";
import copyIcon from "../assets/images/share/copy.svg";
import { message } from "antd";
import { useState, useEffect } from "react";
import { getShareableId } from "../dataManager/Auth";
import { isKiosk, openNativeShareModal } from "../utilities/helper";
import QRCode from "react-qr-code";

const ShareModal = ({
  open,
  onClose,
  id,
  type,
  generateLink = true,
  textContent = "Here’s what Jivi AI says about my health – explore yours too.",
  link = null,
}) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [shareUrl, setShareUrl] = useState("");

  useEffect(() => {
    if (open) {
      if (generateLink) shareContent();
      else setShareUrl(window.location.href);

      if (shareUrl && shareUrl != "") {
        openNativeShareModal(textContent, shareUrl);
      }
    }
  }, [open]);

  useEffect(() => {
    if (shareUrl && shareUrl != "") {
      openNativeShareModal(textContent, shareUrl);
    }
  }, [shareUrl]);

  const shareContent = () => {
    if (link) {
      setShareUrl(link);
    } else {
      if (!shareUrl || shareUrl == "") {
        getShareableId({
          entityUrl: isKiosk()
            ? window.location.href.replace(
                window.location.host,
                import.meta.env.VITE_JIVI_URL
              )
            : window.location.href,
          entityType: type,
          entityReferenceId: id,
        }).then((res) => {
          const shareableUrl = res.data.data.shareableUrl;
          setShareUrl(shareableUrl);
        });
      }
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl);
    messageApi.success(t("link_copied"));
  };

  return isKiosk() ? (
    <Modal
      open={open}
      title={<div className="text-title-small">{t("your_sharable_code")}</div>}
      onCancel={onClose}
      footer={null}
      closeIcon={null}
      centered={true}
      width={"80%"}
    >
      <div className="flex flex-col justify-center items-center gap-4 h-full w-full p-6">
        <div className="w-full text-center text-neutral-700 text-title-medium">
          {t("get_this_analysis_in_your_phone_by_just_scanning_the_qr_code")}
        </div>
        <QRCode value={shareUrl} />
      </div>
    </Modal>
  ) : (
    <Drawer
      placement={"bottom"}
      closable={false}
      onClose={onClose}
      open={open}
      height={200}
      className="rounded-t-xl"
    >
      {contextHolder}
      <div className="flex flex-col justify-center items-center gap-4 h-full w-full">
        <p className="text-body-large">{t("copy_your_shareable_link")}</p>
        <div
          className="flex items-center justify-between w-full border px-4 py-2 rounded-xl cursor-pointer"
          onClick={copyToClipboard}
        >
          <p className="overflow-ellipsis truncate">{shareUrl}</p>
          <div className="flex items-center justify-center">
            <img className="min-w-6 min-h-6" src={copyIcon} alt="copy" />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ShareModal;
