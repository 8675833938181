import * as SERVICEs from "../services/Screening";

export const getScreeningChat = (payload) => {
  return SERVICEs.getScreeningChat(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getScreeningDDX = (payload) => {
  return SERVICEs.getScreeningDDX(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};
