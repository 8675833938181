import { useTranslation } from "react-i18next";
import homeKiosk from "../assets/images/backgrounds/homeKiosk.png";
import { useEffect, useRef, useState } from "react";
import { Spin } from "antd";

const LoadingPage = ({
  loadingTexts = [
    {
      text: "processing_your_symptoms",
      time: 2000,
    },
    {
      text: "evaluating_diagnostic_pathways",
      time: 3000,
    },
    {
      text: "scanning_medical_journals",
      time: 5000,
    },
    {
      text: "prioritizing_conditions",
      time: 3000,
    },
  ],
}) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const interval = useRef(null);

  useEffect(() => {
    interval.current = setTimeout(() => {
      if (currentStep < loadingTexts.length - 1) {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    }, loadingTexts[currentStep].time);
    return () => clearTimeout(interval.current);
  }, [currentStep]);

  return (
    <div
      className="w-full grow flex flex-col justify-start items-center gap-6 relative"
      style={{
        backgroundImage: `url(${homeKiosk})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="w-full h-full flex flex-col justify-center items-center p-6 gap-10">
        <div className="w-full h-1/3 flex flex-col justify-between items-start">
          {loadingTexts.map((text, index) => (
            <div
              key={index}
              className={`w-full flex items-center gap-2 ${
                currentStep >= index ? "opacity-100" : "opacity-50"
              }`}
            >
              <div
                className={`w-10 h-10 ${currentStep == index ? "shadow-xl bg-white" : "bg-primary"}  rounded-full flex justify-center items-center text-white`}
              >
                {currentStep == index ? (
                  <Spin size="large" />
                ) : (
                  <div className="text-title-medium">{index + 1}</div>
                )}
              </div>
              <div
                key={index}
                className={`${currentStep == index ? "text-title-large" : "text-title-medium"}`}
              >
                {t(text.text)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
